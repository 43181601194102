import React, { Component } from "react";
import "./Result.css";
import { Firebase_Config } from "../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";
import Alert from "react-bootstrap/Alert";

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      page1: 1,
      page2: 0,
      page3: 0,
      page4: 0,
      page5: 0,
      page6: 0,
      page7: 0,
      page8: 0,
      page9: 0,
      page10: 0,

      etat1: 0,
      etat2: 0,
      etat3: 0,
      etat4: 0,
      questions: [],
      questions1: [],
      questions2: [],
      questions3: [],
      nomEquipe: "",
      PointEnigme1: 0,
      PointEnigme2: 0,
      PointEnigme3: 0,
      point: "",
      Point1: 0,
      Point2: 0,
      point3: 0,
      date: "",
    };

    this.handleChangepage2 = this.handleChangepage2.bind(this);

    this.handleChangepage1 = this.handleChangepage1.bind(this);
    this.handleChangepage3 = this.handleChangepage3.bind(this);

    this.handleChangepage4 = this.handleChangepage4.bind(this);
    this.handleChangepage5 = this.handleChangepage5.bind(this);

    this.handleChangepage6 = this.handleChangepage6.bind(this);
    this.handleChangepage7 = this.handleChangepage7.bind(this);

    this.handleChangepage8 = this.handleChangepage8.bind(this);
    this.handleChangepage9 = this.handleChangepage9.bind(this);

    this.handleChangepage10 = this.handleChangepage10.bind(this);

    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }

    this.db = firebase.database().ref().child("Festiresult");
    this.db1 = firebase.database().ref().child("Festiresult1");
    this.db2 = firebase.database().ref().child("Festiresult2");
    this.db3 = firebase.database().ref().child("Festiresult3");
  }

  componentWillMount() {
    /*
    const prequestions = this.state.questions;
    this.db.on("child_added", (snap) => {
      prequestions.push({
        id: snap.key,
        nomEquipe: snap.val().nomEquipe,
      });
      this.setState({ questions: prequestions });
    });*/
  }
  componentDidMount() {
    const prequestions1 = this.state.questions1;
    const prequestions2 = this.state.questions2;
    const prequestions3 = this.state.questions3;
    this.db1.on("child_added", (snap) => {
      prequestions1.push({
        id: snap.key,
        nomEquipe: snap.val().nomEquipe,
        PointEnigme1: snap.val().PointEnigme1,
      });
      this.setState({ questions1: prequestions1 });
    });

    this.db2.on("child_added", (snap) => {
      prequestions2.push({
        id: snap.key,
        nomEquipe: snap.val().nomEquipe,
        PointEnigme2: snap.val().PointEnigme2,
      });
      this.setState({ questions2: prequestions2 });
    });

    this.db3.on("child_added", (snap) => {
      prequestions3.push({
        id: snap.key,
        nomEquipe: snap.val().nomEquipe,
        PointEnigme3: snap.val().PointEnigme3,
        date: snap.val().date,
      });
      this.setState({ questions3: prequestions3 });
    });
  }

  AfficherQuizz1 = () => {
    if (this.state.etat1 !== 1) {
      firebase
        .database()
        .ref("activerEnigme1")
        .set(1)
        .then(this.setState({ etat1: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz1 = () => {
    if (this.state.etat1 !== 0) {
      firebase
        .database()
        .ref("activerEnigme1")
        .set(0)
        .then(this.setState({ etat1: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  //

  AfficherQuizz2 = () => {
    if (this.state.etat2 !== 1) {
      firebase
        .database()
        .ref("activerEnigme2")
        .set(1)
        .then(this.setState({ etat2: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz2 = () => {
    if (this.state.etat2 !== 0) {
      firebase
        .database()
        .ref("activerEnigme2")
        .set(0)
        .then(this.setState({ etat2: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  //
  AfficherQuizz3 = () => {
    if (this.state.etat3 !== 1) {
      firebase
        .database()
        .ref("activerEnigme3")
        .set(1)
        .then(this.setState({ etat3: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz3 = () => {
    if (this.state.etat3 !== 0) {
      firebase
        .database()
        .ref("activerEnigme3")
        .set(0)
        .then(this.setState({ etat3: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  AfficherQuizz4 = () => {
    if (this.state.etat4 !== 1) {
      firebase
        .database()
        .ref("activerEnigme4")
        .set(1)
        .then(this.setState({ etat4: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  };

  CacherQuizz1 = () => {
    if (this.state.etat4 !== 0) {
      firebase
        .database()
        .ref("activerEnigme4")
        .set(0)
        .then(this.setState({ etat4: 0 }));
      console.log("Quiz Cacher");
    } else alert("le Quizz est déjà caché");
  };

  handleChangepage1() {
    this.setState({ page: 1 });
  }
  handleChangepage2() {
    this.setState({ page: 2 });
  }
  handleChangepage3() {
    this.setState({ page: 3 });
  }
  handleChangepage4() {
    this.setState({ page: 4 });
  }
  handleChangepage5() {
    this.setState({ page: 5 });
    if (this.state.etat1 !== 1) {
      firebase
        .database()
        .ref("activerEnigme1")
        .set(1)
        .then(this.setState({ etat1: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  }
  handleChangepage6() {
    this.setState({ page: 6 });
  }
  handleChangepage7() {
    this.setState({ page: 7 });
    if (this.state.etat2 !== 1) {
      firebase
        .database()
        .ref("activerEnigme2")
        .set(1)
        .then(this.setState({ etat2: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  }
  handleChangepage8() {
    this.setState({ page: 8 });
  }
  handleChangepage9() {
    this.setState({ page: 9 });
    if (this.state.etat3 !== 1) {
      firebase
        .database()
        .ref("activerEnigme3")
        .set(1)
        .then(this.setState({ etat3: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  }
  handleChangepage10() {
    this.setState({ page: 10 });
    if (this.state.etat4 !== 1) {
      firebase
        .database()
        .ref("activerEnigme4")
        .set(1)
        .then(this.setState({ etat4: 1 }));
      console.log("Afficher Quiz");
    } else alert("le Quizz est déjà affiché");
  }

  render() {
    var templateAccueil = (
      <div className="containeRsulat1">
        <div className="bouton">
          <div className="btn1."></div>
          <div className="btn2." onClick={this.handleChangepage2}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );

    var templatepage2 = (
      <div className="containeRsulatpage2">
        <div className="bouton">
          <div className="btn1." onClick={this.handleChangepage1}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20gauche.png"
              width={50}
            />
          </div>
          <div className="btn2." onClick={this.handleChangepage3}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );

    var templatepage3 = (
      <div className="containeRsulatpage3">
        <div className="bouton">
          <div className="btn1." onClick={this.handleChangepage2}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20gauche.png"
              width={50}
            />
          </div>
          <div className="btn2." onClick={this.handleChangepage4}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );

    var templatepage4 = (
      <div className="containeRsulatpage4">
        <div className="bouton">
          <div className="btn1." onClick={this.handleChangepage3}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20gauche.png"
              width={50}
            />
          </div>
          <div
            className="btn2."
            onClick={(this.AfficherQuizz1, this.handleChangepage5)}
          >
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );

    var templatepage5 = (
      <div className="containeRsulatpage5">
        <div className="bouton">
          <div className="btn1." onClick={this.handleChangepage4}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20gauche.png"
              width={50}
            />
          </div>
          <div className="btn2." onClick={this.handleChangepage6}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );
    var templatepage6 = (
      <div className="containeRsulatpage6">
        {this.state.questions1.map((home) => {
          return (
            <div key={home.id} className="alertFesti" variant="dark">
              <h4 className="textNoir"> {home.nomEquipe} </h4>{" "}
              <h4 className="textNoir"> {home.PointEnigme1} points</h4>{" "}
            </div>
          );
        })}
        <div className="bouton">
          <div className="btn1." onClick={this.handleChangepage5}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20gauche.png"
              width={50}
            />
          </div>
          <div className="btn2." onClick={this.handleChangepage7}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );
    var templatepage7 = (
      <div className="containeRsulatpage7">
        <div className="bouton">
          <div className="btn1." onClick={this.handleChangepage6}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20gauche.png"
              width={50}
            />
          </div>
          <div className="btn2." onClick={this.handleChangepage8}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );
    var templatepage8 = (
      <div className="containeRsulatpage8">
        {this.state.questions2.map((home) => {
          return (
            <div key={home.id} className="alertFesti" variant="dark">
              <h4 className="textNoir"> {home.nomEquipe} </h4>{" "}
              <h4 className="textNoir"> {home.PointEnigme2} points</h4>{" "}
            </div>
          );
        })}
        <div className="bouton">
          <div className="btn1." onClick={this.handleChangepage7}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20gauche.png"
              width={50}
            />
          </div>
          <div className="btn2." onClick={this.handleChangepage9}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );
    var templatepage9 = (
      <div className="containeRsulatpage9">
        <div className="bouton">
          <div className="btn1." onClick={this.handleChangepage8}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20gauche.png"
              width={50}
            />
          </div>
          <div className="btn2." onClick={this.handleChangepage10}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );
    var templatepage10 = (
      <div className="containeRsulatpage10">
        {this.state.questions3.map((home) => {
          return (
            <div key={home.id} className="alertFestiD" variant="dark">
              <h4 className="textNoir"> {home.nomEquipe} </h4>{" "}
              <h4 className="textNoir"> {home.PointEnigme3} points</h4>{" "}
              <h4 className="textNoir"> Temps : {home.date} </h4>{" "}
            </div>
          );
        })}
        <div className="bouton">
          <div className="btn1." onClick={this.handleChangepage9}>
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20gauche.png"
              width={50}
            />
          </div>
          <div className="btn2.">
            <img
              src="https://festicomsante.henri8.com/result/IMG/fleche%20droite.png"
              width={50}
            />
          </div>
        </div>
      </div>
    );

    if (this.state.page == 1) {
      var template = templateAccueil;
    }

    if (this.state.page == 2) {
      var template = templatepage2;
    }

    if (this.state.page == 3) {
      var template = templatepage3;
    }

    if (this.state.page == 4) {
      var template = templatepage4;
    }
    if (this.state.page == 5) {
      var template = templatepage5;
    }

    if (this.state.page == 6) {
      var template = templatepage6;
    }

    if (this.state.page == 7) {
      var template = templatepage7;
    }

    if (this.state.page == 8) {
      var template = templatepage8;
    }
    if (this.state.page == 9) {
      var template = templatepage9;
    }

    if (this.state.page == 10) {
      var template = templatepage10;
    }

    return <div>{template}</div>;
  }
}

export default Result;
