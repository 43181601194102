import React, { Component } from "react";
import "./Accueil.css";

import { Firebase_Config } from "../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

class Accueil extends Component {
  constructor(props, context) {
    super(props, context);

    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
    this.state = {
      pointTotal: 0, // point total
      etat: 1, // etat pour slash screen
      tmpNom: "", // nom d'équipe temporaire handle change
      nomEquipe: "", // le nom de l'équipe
      errors: {}, // json pour l'erreur
      activerEnigme1: 1, // active l'énigme 1
      activerEnigme2: 1, // active l'énigme 2
      activerEnigme3: 0, // active l'énigme 3
      jouerEnigme1: 0, // jouer l'énigme 1
      jouerEnigme2: 0, // jouer l'énigme 2
      jouerEnigme3: 0, // jouer l'énigme 3

      tmpEnigme1: "", // handle change énigme 1
      Enigme1: "", // nom de l'enigme 1
      tmpEnigme2: "", // handle change énigme 2
      Enigme2: "", // nom de l'enigme 2
      tmpEnigme3: "", // handle change énigme 3
      Enigme3: "", // nom de l'enigme 3

      Reponse1: "claim", // réponses 1
      Reponse2: "cible", // réponses 2
      Reponse3: "concept", // réponses 3

      PointEnigme1: 0, // point énigme 1
      Indice: 0, // indice énigme 1

      PointEnigme2: 0, // point énigme 2
      Indice2: 0, // indice énigme 2

      PointEnigme3: 0, // point énigme 3
      Indice3: 0, // indice énigme 3

      etatAlerteBad: 0, // Alerte mauvaise réponse
      etatAlerteGood: 0, // Alerte bonne réponses

      EssaieEnigme1: 0, // nombre d'essaie enigme 1
      EssaieEnigme2: 0, // nombre d'essaie enigme 2
      EssaieEnigme3: 0, // nombre d'essaie enigme 3

      etatAlertetimeout: 0, // Alerte timeout
      pointTotal: 0,
      uid: "",
      result: {},
    };
    this.handleChangeNom = this.handleChangeNom.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidation = this.handleValidation.bind(this);

    this.handJouerEnigme1 = this.handJouerEnigme1.bind(this);
    this.handleChangeEnigme1 = this.handleChangeEnigme1.bind(this);
    this.handleSubmitEnigme1 = this.handleSubmitEnigme1.bind(this);
    this.handleIndicEnigme1 = this.handleIndicEnigme1.bind(this);

    this.handJouerEnigme2 = this.handJouerEnigme2.bind(this);
    this.handleChangeEnigme2 = this.handleChangeEnigme2.bind(this);
    this.handleSubmitEnigme2 = this.handleSubmitEnigme2.bind(this);
    this.handleIndicEnigme2 = this.handleIndicEnigme2.bind(this);

    this.handJouerEnigme3 = this.handJouerEnigme3.bind(this);
    this.handleChangeEnigme3 = this.handleChangeEnigme3.bind(this);
    this.handleSubmitEnigme3 = this.handleSubmitEnigme3.bind(this);
    this.handleIndicEnigme3 = this.handleIndicEnigme3.bind(this);
  }
  componentWillMount() {
    this.db = firebase.database();

    this.db.ref("activerEnigme1").on("value", (snap) => {
      this.setState({ activerEnigme1: snap.val() });
    });
    this.db.ref("jouerEnigme1").on("value", (snap) => {
      this.setState({ jouerEnigme1: snap.val() });
    });

    //Enigme 2

    this.db.ref("activerEnigme2").on("value", (snap) => {
      this.setState({ activerEnigme2: snap.val() });
    });
    this.db.ref("jouerEnigme2").on("value", (snap) => {
      this.setState({ jouerEnigme2: snap.val() });
    });

    //Enigme 3

    this.db.ref("activerEnigme3").on("value", (snap) => {
      this.setState({ activerEnigme3: snap.val() });
    });
    this.db.ref("jouerEnigme3").on("value", (snap) => {
      this.setState({ jouerEnigme3: snap.val() });
    });

    //Enigme 4

    this.db.ref("activerEnigme4").on("value", (snap) => {
      this.setState({ activerEnigme4: snap.val() });
    });
  }
  componentDidMount() {
    /*
    var aValue = localStorage.getItem("nomEquipe");
    if ((aValue = !undefined)) {
      this.setState({ nomEquipe: aValue });
    }

    var aValue1 = localStorage.getItem("PointEnigme1");
    if ((aValue1 = !undefined)) {
      this.setState({ PointEnigme1: aValue1 });
    }

    var aValue2 = localStorage.getItem("PointEnigme2");
    if ((aValue2 = !undefined)) {
      this.setState({ PointEnigme2: aValue2 });
    }

    var aValue3 = localStorage.getItem("PointEnigme3");
    if ((aValue3 = !undefined)) {
      this.setState({ PointEnigme3: aValue3 });
    }*/
  }

  //Validation
  handleValidation() {
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Name
    if (this.state.tmpNom == "") {
      formIsValid = false;
      errors["nomEquipe"] = "Cannot be empty";
    }

    if (this.state.tmpNom !== "undefined") {
      if (!this.state.nomEquipe.match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["nomEquipe"] = "Le nom d'équipe ne peux pas être vide";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleValidation1() {
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Name

    if (this.state.tmpEnigme1 == "") {
      formIsValid = false;
      errors["Enigme1"] = "Le champs Enigme 1 ne peut pas être vide";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  //fin validation

  //Changement de nom temps réel
  handleChangeNom(event) {
    this.setState({ tmpNom: event.target.value });
    console.log(this.state.nomEquipe);
  }
  //Fin changement de nom temps réel

  //submit
  handleSubmit(event) {
    this.ConfirmeInscription(event);
    event.preventDefault();
  }

  ConfirmeInscription = (event) => {
    localStorage.setItem("nomEquipe", this.state.tmpNom);
    this.handleValidation();
    if (this.state.tmpNom != "") {
      let uid = firebase.database().ref().child("Festiresult").push().key;
      let uid2 = firebase.database().ref().child("Festiresult").push().key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        nomEquipe: this.state.tmpNom,
        PointEnigme1: this.state.PointEnigme1,
        PointEnigme2: this.state.PointEnigme2,
        PointEnigme3: this.state.PointEnigme3,
        pointTotal: this.state.pointTotal,
        date: n,
      };

      let updates = {};
      let updates2 = {};

      updates["/Festiresult/" + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(
          this.setState(
            { nomEquipe: this.state.tmpNom, uid: data.id },
            console.log(this.state.uid + "***********************")
          )
        );

      updates2["/FestiresultSave/" + uid] = data;
      firebase.database().ref().update(updates2);

      event.preventDefault();

      console.log(this.state.nomEquipe);
    }
  };
  // fin submit

  //bouton jouer Enigme
  handJouerEnigme1 = () => {
    this.setState({ jouerEnigme1: 1 });
    console.log("*********" + this.state.jouerEnigme1);
  };

  handJouerEnigme2 = () => {
    this.setState({ jouerEnigme2: 1 });
    console.log("*********" + this.state.jouerEnigme2);
  };

  handJouerEnigme3 = () => {
    this.setState({ jouerEnigme3: 1 });
    console.log("*********" + this.state.jouerEnigme3);
  };

  //Enigme 1
  handleChangeEnigme1(event) {
    this.setState({ tmpEnigme1: event.target.value });
    console.log(this.state.tmpEnigme1);
  }

  //Enigme 2
  handleChangeEnigme2(event) {
    this.setState({ tmpEnigme2: event.target.value });
    console.log(this.state.tmpEnigme2);
  }

  //Enigme 3
  handleChangeEnigme3(event) {
    this.setState({ tmpEnigme3: event.target.value });
    console.log(this.state.tmpEnigme2);
  }
  //submit Enigme 1
  handleSubmitEnigme1(event) {
    this.ConfirmeInscriptionEnigme1(event);
    event.preventDefault();
  }

  //submit Enigme 2
  handleSubmitEnigme2(event) {
    this.ConfirmeInscriptionEnigme2(event);
    event.preventDefault();
  }

  //submit Enigme 3
  handleSubmitEnigme3(event) {
    this.ConfirmeInscriptionEnigme3(event);
    event.preventDefault();
  }

  //confirmation enigme 1
  ConfirmeInscriptionEnigme1 = (event) => {
    //this.handleValidation2();

    if (this.state.tmpEnigme1 != "") {
      this.setState({
        Enigme1: this.state.tmpEnigme1.toLowerCase(),
        EssaieEnigme1: this.state.EssaieEnigme1 + 1,
      });
    }

    setTimeout(() => {
      if (
        this.state.Enigme1 != "" &&
        this.state.Enigme1 != this.state.Reponse1 &&
        this.state.EssaieEnigme1 <= 3
      ) {
        this.setState({ etatAlerteBad: 1 });
        setTimeout(() => {
          this.setState({ etatAlerteBad: 0 });
        }, "3000");
      } else if (
        this.state.Enigme1 != "" &&
        this.state.Enigme1 == this.state.Reponse1 &&
        this.state.EssaieEnigme1 <= 3
      ) {
        if (this.state.Indice === 0) {
          if (this.state.EssaieEnigme1 === 1) {
            this.setState({ etatAlerteGood: 1, PointEnigme1: +50 });
          } else if (this.state.EssaieEnigme1 === 2) {
            this.setState({ etatAlerteGood: 1, PointEnigme1: +30 });
          } else {
            this.setState({ etatAlerteGood: 1, PointEnigme1: +20 });
          }

          setTimeout(() => {
            this.setState({ etatAlerteGood: 0 });
            localStorage.setItem("PointEnigme1", this.state.PointEnigme1);
          }, "3000");
          let uid1 = firebase.database().ref().child("Festiresult1").push().key;

          let d = new Date();
          let n = d.toLocaleString();
          let data = {
            id: uid1,
            nomEquipe: this.state.nomEquipe,
            PointEnigme1: this.state.PointEnigme1,
            date: n,
          };

          let updates = {};

          updates["/Festiresult1/" + uid1] = data;
          firebase.database().ref().update(updates);
        } else {
          if (this.state.EssaieEnigme1 === 1) {
            this.setState({ etatAlerteGood: 1, PointEnigme1: +40 });
          } else if (this.state.EssaieEnigme1 === 2) {
            this.setState({ etatAlerteGood: 1, PointEnigme1: +20 });
          } else {
            this.setState({ etatAlerteGood: 1, PointEnigme1: +10 });
          }

          setTimeout(() => {
            this.setState({ etatAlerteGood: 0 });
            localStorage.setItem("PointEnigme1", this.state.PointEnigme1);
          }, "3000");
          let uid1 = firebase.database().ref().child("Festiresult1").push().key;

          let d = new Date();
          let n = d.toLocaleString();
          let data = {
            id: uid1,
            nomEquipe: this.state.nomEquipe,
            PointEnigme1: this.state.PointEnigme1,
            date: n,
          };

          let updates = {};

          updates["/Festiresult1/" + uid1] = data;
          firebase.database().ref().update(updates);
        }
      } else {
        this.setState({ etatAlertetimeout: 1 });
        setTimeout(() => {
          this.setState({ etatAlertetimeout: 0, PointEnigme1: 0 });
        }, "3000");
      }

      event.preventDefault();
    }, "100");
  };
  // fin submit Enigme 1

  //confirmation enigme 2
  ConfirmeInscriptionEnigme2 = (event) => {
    //this.handleValidation2();
    if (this.state.tmpEnigme2 != "") {
      this.setState({
        Enigme2: this.state.tmpEnigme2.toLowerCase(),
        EssaieEnigme2: this.state.EssaieEnigme2 + 1,
      });
    }

    setTimeout(() => {
      console.log("EssaieEnigme2 =" + this.state.EssaieEnigme2);
      console.log("reponses enigme2 =" + this.state.Enigme2);
      console.log("reponses state =" + this.state.Reponse2);
      if (
        this.state.Enigme2 != "" &&
        this.state.Enigme2 != this.state.Reponse2 &&
        this.state.EssaieEnigme2 <= 3
      ) {
        this.setState({ etatAlerteBad: 1 });
        setTimeout(() => {
          this.setState({ etatAlerteBad: 0 });
        }, "3000");
      } else if (
        this.state.Enigme2 != "" &&
        this.state.Enigme2 == this.state.Reponse2 &&
        this.state.EssaieEnigme2 <= 3
      ) {
        if (this.state.Indice2 === 0) {
          console.log("mon essaie enigme 2");
          if (this.state.EssaieEnigme2 === 1) {
            this.setState({ etatAlerteGood: 1, PointEnigme2: +50 });
          } else if (this.state.EssaieEnigme2 === 2) {
            this.setState({ etatAlerteGood: 1, PointEnigme2: +30 });
          } else {
            this.setState({ etatAlerteGood: 1, PointEnigme2: +20 });
          }
          setTimeout(() => {
            this.setState({ etatAlerteGood: 0 });
            localStorage.setItem("PointEnigme2", this.state.PointEnigme2);
          }, "3000");

          let uid2 = firebase.database().ref().child("Festiresult2").push().key;

          let d = new Date();
          let n = d.toLocaleString();
          let data = {
            id: uid2,
            nomEquipe: this.state.nomEquipe,
            PointEnigme2: this.state.PointEnigme1 + this.state.PointEnigme2,
            date: n,
          };

          let updates = {};

          updates["/Festiresult2/" + uid2] = data;
          firebase.database().ref().update(updates);
        } else {
          if (this.state.EssaieEnigme2 === 1) {
            this.setState({ etatAlerteGood: 1, PointEnigme2: +40 });
          } else if (this.state.EssaieEnigme2 === 2) {
            this.setState({ etatAlerteGood: 1, PointEnigme2: +20 });
          } else {
            this.setState({ etatAlerteGood: 1, PointEnigme2: +10 });
          }
          setTimeout(() => {
            this.setState({ etatAlerteGood: 0 });
            localStorage.setItem("PointEnigme2", this.state.PointEnigme2);
          }, "3000");

          let uid2 = firebase.database().ref().child("Festiresult2").push().key;

          let d = new Date();
          let n = d.toLocaleString();
          let data = {
            id: uid2,
            nomEquipe: this.state.nomEquipe,
            PointEnigme2: this.state.PointEnigme1 + this.state.PointEnigme2,
            date: n,
          };

          let updates = {};

          updates["/Festiresult2/" + uid2] = data;
          firebase.database().ref().update(updates);
        }
      } else {
        this.setState({ etatAlertetimeout: 1 });
        setTimeout(() => {
          this.setState({ etatAlertetimeout: 0, PointEnigme2: 0 });
        }, "3000");
      }

      event.preventDefault();
    }, "100");
  };
  // fin submit Enigme 2

  //confirmation enigme 3
  ConfirmeInscriptionEnigme3 = (event) => {
    //this.handleValidation2();
    if (this.state.tmpEnigme3 != "") {
      this.setState({
        Enigme3: this.state.tmpEnigme3.toLowerCase(),
        EssaieEnigme3: this.state.EssaieEnigme3 + 1,
      });
    }

    setTimeout(() => {
      console.log("EssaieEnigme3 =" + this.state.EssaieEnigme3);
      if (
        this.state.Enigme3 != "" &&
        this.state.Enigme3 != this.state.Reponse3 &&
        this.state.EssaieEnigme3 <= 3
      ) {
        this.setState({ etatAlerteBad: 1 });
        setTimeout(() => {
          this.setState({ etatAlerteBad: 0 });
        }, "3000");
      } else if (
        this.state.Enigme3 != "" &&
        this.state.Enigme3 == this.state.Reponse3 &&
        this.state.EssaieEnigme3 <= 3
      ) {
        if (this.state.Indice3 === 0) {
          if (this.state.EssaieEnigme3 === 1) {
            this.setState({ etatAlerteGood: 1, PointEnigme3: +50 });
          } else if (this.state.EssaieEnigme3 === 2) {
            this.setState({ etatAlerteGood: 1, PointEnigme3: +30 });
          } else {
            this.setState({ etatAlerteGood: 1, PointEnigme3: +20 });
          }

          let uid3 = firebase.database().ref().child("Festiresult3").push().key;

          let d = new Date();
          let n = d.toLocaleString();
          let data = {
            id: uid3,
            nomEquipe: this.state.nomEquipe,
            PointEnigme3:
              this.state.PointEnigme1 +
              this.state.PointEnigme2 +
              this.state.PointEnigme3,
            date: n,
          };

          let updates = {};

          updates["/Festiresult3/" + uid3] = data;
          firebase.database().ref().update(updates);
        } else {
          if (this.state.EssaieEnigme3 === 1) {
            this.setState({ etatAlerteGood: 1, PointEnigme3: +40 });
          } else if (this.state.EssaieEnigme3 === 2) {
            this.setState({ etatAlerteGood: 1, PointEnigme3: +20 });
          } else {
            this.setState({ etatAlerteGood: 1, PointEnigme3: +10 });
          }
          let uid3 = firebase.database().ref().child("Festiresult3").push().key;

          let d = new Date();
          let n = d.toLocaleString();
          let data = {
            id: uid3,
            nomEquipe: this.state.nomEquipe,
            PointEnigme3:
              this.state.PointEnigme1 +
              this.state.PointEnigme2 +
              this.state.PointEnigme3,
            date: n,
          };

          let updates = {};

          updates["/Festiresult3/" + uid3] = data;
          firebase.database().ref().update(updates);
        }

        setTimeout(() => {
          this.setState({ etatAlerteGood: 0 });
          localStorage.setItem("PointEnigme3", this.state.PointEnigme3);
        }, "3000");
      } else {
        this.setState({ etatAlertetimeout: 1 });
        setTimeout(() => {
          this.setState({ etatAlertetimeout: 0, PointEnigme3: 0 });
        }, "3000");
      }

      event.preventDefault();
    }, "100");
  };
  // fin submit Enigme 3

  // indice Enigme 1

  handleIndicEnigme1 = () => {
    if (this.state.EssaieEnigme1 < 3) {
      this.setState({ Indice: 1 });
    }

    console.log("*********" + this.state.PointEnigme1);
  };

  // indice Enigme 2

  handleIndicEnigme2 = () => {
    if (this.state.EssaieEnigme2 < 3) {
      this.setState({ Indice2: 1 });
    }
    console.log("*********" + this.state.PointEnigme2);
  };

  // indice Enigme 3

  handleIndicEnigme3 = () => {
    if (this.state.EssaieEnigme3 < 3) {
      this.setState({ Indice3: 1 });
      console.log("*********" + this.state.PointEnigme3);
    }
    console.log("*********" + this.state.PointEnigme3);
  };

  render() {
    if (this.state.Indice === 1) {
      var templateIndiceEnigme1 = (
        <div className="Indice">SYNONYME DE SLOGAN</div>
      );
    }

    if (this.state.Indice2 === 1) {
      var templateIndiceEnigme2 = (
        <div className="Indice">METTRE DE LE MILLE</div>
      );
    }

    if (this.state.Indice3 === 1) {
      var templateIndiceEnigme3 = (
        <div className="Indice">SYNONYME DE IDÉE</div>
      );
    }

    if (this.state.etatAlerteBad === 1) {
      var templateAlerteBad = (
        <div className="backgroundAlerte">
          <div className="AlertBAD">
            <div> OUPS !</div>
            <div>Essaie encore...</div>
          </div>
        </div>
      );
    }

    if (this.state.etatAlertetimeout === 1) {
      var templateAlerteBad = (
        <div className="backgroundAlerte">
          <div className="AlertBAD">
            <div> OUPS !</div>
            <div>Vous n'avez plus d'essai</div>
          </div>
        </div>
      );
    }

    if (this.state.etatAlerteGood === 1) {
      var templateAlerteBad = (
        <div className="backgroundAlerte">
          <div className="AlertGOOD">
            <div> BRAVO !</div>
            <div>Bien joué !</div>

            <div>
              Vous avez :{" "}
              {this.state.PointEnigme1 +
                this.state.PointEnigme2 +
                this.state.PointEnigme3}
            </div>
          </div>
        </div>
      );
    }

    // Sans nom d'équipe
    if (this.state.etat === 1 && this.state.nomEquipe == "") {
      var template1 = (
        <div>
          <div className="CarreRose">
            <p>Avant de commencer, choisissez votre nom d'équipe !</p>
          </div>

          <form>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="nomEquipe"
                placeholder="Nom d'équipe"
                required
                value={this.state.tmpNom}
                onChange={this.handleChangeNom}
                maxLength={10}
              />
              <span style={{ color: "red", fontSize: 8 }}>
                {this.state.errors["nomEquipe"]}
              </span>
            </div>
            <p className="MaxLettre">(10 lettres max)</p>

            <button onClick={this.handleSubmit}> Valider</button>
          </form>
        </div>
      );
    }

    // bouton énigme 1
    if (this.state.activerEnigme1 === 1) {
      var templateactiverEnigme1 = (
        <div className="btnEnigme" onClick={this.handJouerEnigme1}>
          {" "}
          1<sup>e</sup> énigme
        </div>
      );
    }

    if (this.state.activerEnigme1 === 0) {
      var templateactiverEnigme1 = (
        <div className="btnEnigmeDisable">
          {" "}
          1<sup>e</sup> énigme
        </div>
      );
    }

    // bouton énigme 2
    if (this.state.activerEnigme2 === 1) {
      var templateactiverEnigme2 = (
        <div className="btnEnigme" onClick={this.handJouerEnigme2}>
          {" "}
          2<sup>e</sup> énigme
        </div>
      );
    }

    if (this.state.activerEnigme2 === 0) {
      var templateactiverEnigme2 = (
        <div className="btnEnigmeDisable">
          {" "}
          2<sup>e</sup> énigme
        </div>
      );
    }

    // bouton énigme 3
    if (this.state.activerEnigme3 === 1) {
      var templateactiverEnigme3 = (
        <div className="btnEnigme" onClick={this.handJouerEnigme3}>
          {" "}
          3<sup>e</sup> énigme
        </div>
      );
    }

    if (this.state.activerEnigme3 === 0) {
      var templateactiverEnigme3 = (
        <div className="btnEnigmeDisable">
          {" "}
          3<sup>e</sup> énigme
        </div>
      );
    }

    //Avec nom d'équipe
    if (this.state.etat === 1 && this.state.nomEquipe !== "") {
      var template1 = (
        <div>
          <div className="CarreRose">
            {" "}
            Vous êtes l'équipe <br></br>
            {this.state.nomEquipe} !
          </div>
          {templateactiverEnigme1}
        </div>
      );
    }

    // Activation Enigme 1
    if (
      this.state.etat === 1 &&
      this.state.nomEquipe !== "" &&
      this.state.activerEnigme1 === 1 &&
      this.state.activerEnigme2 === 0 &&
      this.state.activerEnigme3 === 0
    ) {
      var template1 = (
        <div>
          <div className="CarreRose">
            {" "}
            <br></br>
            Vous pouvez jouer ! <br></br>
          </div>
          {templateactiverEnigme1}
        </div>
      );
    }

    if (
      this.state.etat === 1 &&
      this.state.nomEquipe !== "" &&
      this.state.activerEnigme1 === 1 &&
      this.state.activerEnigme2 === 0 &&
      this.state.activerEnigme3 === 0 &&
      this.state.jouerEnigme1 === 1
    ) {
      var template1 = (
        <div>
          <div className="CarreRoseEnigme">
            {" "}
            <br></br>
            En quelques mots bien choisis du visuel principal, il porte le
            récit.
            <br></br>
          </div>

          <form>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="Enigme1"
                placeholder="Enigme 1"
                required
                value={this.state.tmpEnigme1}
                onChange={this.handleChangeEnigme1}
                maxLength={10}
              />
              <span style={{ color: "red", fontSize: 12 }}>
                {this.state.errors["Enigme1"]}
              </span>
            </div>
            <p className="MaxLettre">(10 lettres max)</p>

            <button onClick={this.handleSubmitEnigme1}> Valider</button>

            <div onClick={this.handleIndicEnigme1} className="btnindice">
              {" "}
              Indice (-10 points)
            </div>
            {templateIndiceEnigme1}
          </form>
          {templateAlerteBad}
        </div>
      );
    }

    // Activation Enigme 2
    if (
      this.state.etat === 1 &&
      this.state.nomEquipe !== "" &&
      this.state.activerEnigme1 === 1 &&
      this.state.activerEnigme2 === 1 &&
      this.state.activerEnigme3 === 0
    ) {
      var template1 = (
        <div>
          <div className="CarreRose">
            {" "}
            <br></br>
            Vous pouvez jouer ! <br></br>
          </div>

          <div className="points">
            {" "}
            Vous avez {this.state.PointEnigme1} points !
          </div>
          {templateactiverEnigme2}
        </div>
      );
    }

    if (
      this.state.etat === 1 &&
      this.state.nomEquipe !== "" &&
      this.state.activerEnigme1 === 1 &&
      this.state.activerEnigme2 === 1 &&
      this.state.activerEnigme3 === 0 &&
      this.state.jouerEnigme2 === 1
    ) {
      var template1 = (
        <div>
          <div className="CarreRoseEnigme">
            {" "}
            <br></br>
            Tout l’art de communiquer consiste à la toucher
            <br></br>
          </div>

          <form>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="Enigme2"
                placeholder="Enigme 2"
                required
                value={this.state.tmpEnigme2}
                onChange={this.handleChangeEnigme2}
                maxLength={10}
              />
              <span style={{ color: "red", fontSize: 12 }}>
                {this.state.errors["Enigme2"]}
              </span>
            </div>
            <p className="MaxLettre">(10 lettres max)</p>

            <button onClick={this.handleSubmitEnigme2}> Valider</button>

            <div onClick={this.handleIndicEnigme2} className="btnindice">
              {" "}
              Indice (-10 points)
            </div>
            {templateIndiceEnigme2}
          </form>
          {templateAlerteBad}
        </div>
      );
    }

    // Activation Enigme 3
    if (
      this.state.etat === 1 &&
      this.state.nomEquipe !== "" &&
      this.state.activerEnigme1 === 1 &&
      this.state.activerEnigme2 === 1 &&
      this.state.activerEnigme3 === 1
    ) {
      var template1 = (
        <div>
          <div className="CarreRose">
            {" "}
            <br></br>
            Vous pouvez jouer ! <br></br>
          </div>

          <div className="points">
            {" "}
            Vous avez {this.state.PointEnigme1 + this.state.PointEnigme2} points
            !
          </div>
          {templateactiverEnigme3}
        </div>
      );
    }

    if (
      this.state.etat === 1 &&
      this.state.nomEquipe !== "" &&
      this.state.activerEnigme1 === 1 &&
      this.state.activerEnigme2 === 1 &&
      this.state.activerEnigme3 === 1 &&
      this.state.jouerEnigme3 === 1
    ) {
      var template1 = (
        <div>
          <div className="CarreRoseEnigme">
            {" "}
            <br></br>
            Bien qu’abstrait, <br></br> sans lui rien ne se fait.
            <br></br>
          </div>

          <form>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="Enigme3"
                placeholder="Enigme 3"
                required
                value={this.state.tmpEnigme3}
                onChange={this.handleChangeEnigme3}
                maxLength={10}
              />
              <span style={{ color: "red", fontSize: 12 }}>
                {this.state.errors["Enigme3"]}
              </span>
            </div>
            <p className="MaxLettre">(10 lettres max)</p>

            <button onClick={this.handleSubmitEnigme3}> Valider</button>

            <div onClick={this.handleIndicEnigme3} className="btnindice">
              {" "}
              Indice (-10 points)
            </div>
            {templateIndiceEnigme3}
          </form>
          {templateAlerteBad}
        </div>
      );
    }

    // Activation bonne réponses
    if (
      this.state.etat === 1 &&
      this.state.nomEquipe !== "" &&
      this.state.activerEnigme1 === 1 &&
      this.state.activerEnigme2 === 1 &&
      this.state.activerEnigme3 === 1 &&
      this.state.activerEnigme4 === 1 &&
      this.state.PointEnigme1 +
        this.state.PointEnigme2 +
        this.state.PointEnigme3 >
        0
    ) {
      var template1 = (
        <div>
          <div className="CarreRose">
            {" "}
            <br></br>
            <div> Bravo les </div>
            <div>{this.state.nomEquipe} !</div> <br></br>
          </div>

          <div className="points">
            {" "}
            Vous avez{" "}
            {this.state.PointEnigme1 +
              this.state.PointEnigme2 +
              this.state.PointEnigme3}{" "}
            points !
          </div>
        </div>
      );
    }

    if (
      this.state.etat === 1 &&
      this.state.nomEquipe !== "" &&
      this.state.activerEnigme1 === 1 &&
      this.state.activerEnigme2 === 1 &&
      this.state.activerEnigme3 === 1 &&
      this.state.activerEnigme4 === 1 &&
      this.state.PointEnigme1 +
        this.state.PointEnigme2 +
        this.state.PointEnigme3 ===
        0
    ) {
      var template1 = (
        <div>
          <div className="CarreRose">
            {" "}
            <br></br>
            <div> Dommage les </div>
            <div>{this.state.nomEquipe}</div> <br></br>
          </div>

          <div className="points">
            {" "}
            Vous restez à{" "}
            {this.state.PointEnigme1 +
              this.state.PointEnigme2 +
              this.state.PointEnigme3}{" "}
            points !
          </div>
        </div>
      );
    }

    // Jouer Enigme 1

    return <div> {template1}</div>;
  }
}

export default Accueil;
