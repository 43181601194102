import React, { Component } from "react";
import { HashRouter as Router, Route, Link } from "react-router-dom";
import Home from "../Components/Home";
import Dashboard from "../Components/Dashboard";
import Admin from "../Components/Admin";
import Chat from "../Components/Chat";
import Moderation from "../Components/Moderation";
import ModerationClient from "../Components/ModerationClient";
import Quiz from "../Components/Quiz";
import Splash from "../Components/Splash";
import Accueil from "../Components/Accueil";
import Result from "../Components/Result";

import Player from "../Components/Player";
import "react-app-polyfill/ie11";

class IndexRoute extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="IndexRoute">
        <Router>
          <div>
            <Route exact path="/" component={Accueil} />

            {/*
            <Route exact path="/" component={Result} />

                        <Route exact path="/" component={Quiz} />


          

            <Route exact path="/:token" component={Admin} />
              <Route exact path="/:token" component={ModerationClient} />
         

                      <Route exact path="/:token" component={ModerationClient} />


            <Route exact path="/:token" component={Admin} />
            <Route exact path="/" component={Player} />
            <Route exact path="/:token" component={Player} />

            <Route exact path="/" component={Player} />
            <Route exact path="/:token" component={Player} />
            <Route exact path="/:token" component={ModerationClient} />

            <Route exact path="/" component={Player} />
            <Route exact path="/:token" component={Player} />
             <Route exact path="/" component={Player} />
            <Route exact path="/:token" component={Player} />

             <Route exact path="/:token" component={ModerationClient} />

<Route exact path="/:token" component={ModerationClient} />
            <Route exact path="/" component={Player} />
            <Route exact path="/:token" component={Player} />
            <Route exact path="/:token" component={Admin} />

             

       

                 <Route exact path="/" component={Dashboard} />
            <Route exact path="/:token" component={Dashboard} />
        

         
  <Route exact path="/:token" component={ModerationClient} />
              


 <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Home} />
  <Route exact path="/:token" component={Home} />
          
            <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Moderation} />
               <Route exact path="/:token" component={Admin} />

                <Route exact path="/:token" component={Moderation} />

       
          
           
            <Route exact path="/:token" component={Admin} />
             
            <Route exact path="/:token" component={Admin} />

             <Route exact path="/:token" component={Moderation} />

             
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/Chat" component={Chat} />
            <Route exact path="/Moderation" component={Moderation} />
            */}
          </div>
        </Router>
      </div>
    );
  }
}

export default IndexRoute;
