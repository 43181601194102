import React, { Component } from "react";
import "./Splash.css";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";

class Splash extends Component {
  componentWillMount() {}
  componentDidMount() {
    setTimeout(() => {
      this.props.history.push("/Aucceil");
      console.log("Retardée d'une seconde.");
    }, "3000");
  }

  render() {
    return (
      <div className="BodyContainer">
        <img
          src="https://festicomsante.henri8.com/IMG_Mobile/festi-code-sante%CC%81-mobile.png"
          width="100%"
        />
      </div>
    );
  }
}

export default Splash;
